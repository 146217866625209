$( document ).ready(function() {

  $('a[href*=\\#]:not([href=\\#],[href*=\\#areas])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
  $('.ytlink').unbind().on( 'click', function( event ) {
    event.preventDefault();
    var yt = event.currentTarget.href.split('=');
    yt = yt[1];
    $('.ytlink').html('<iframe class="ytframe" width="720" height="405" src="https://www.youtube-nocookie.com/embed/'+yt+'?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
  });
  
  $('[data-type="parallax"]').each(function(){
    var $scroll = $(this);
    $scroll.css({ position: 'relative',
                  top: 0 });
    var $sPos = ($(window).scrollTop()/3)*-1;
    if ( $sPos*-1 < $scroll.height()+100 ) {
      $scroll.css({ top: $sPos+'px' });
    } else {
      $scroll.css({ top: '-322px' });
    }
    $(window).scroll(function() {
      $sPos = ($(window).scrollTop()/3)*-1;
      if ( $sPos*-1 < $scroll.height()+100 ) {
        $scroll.css({ top: $sPos+'px' });
      }
    });
  });
  /*if ( $('.logo img').height() > $(window).height()/2 ) {
    $('.container.content').css({marginTop: ($('.logo img').height()/2)+100 });
  } else {
    $('.container.content').css({marginTop: $('.logo img').height()+100 });  
  }*/
  
  $('.bbstro').on( 'click', function(e) {
    e.preventDefault();
    $( ".fadetoblack" ).css({ zIndex: 2000 });
    $( ".fadetoblack" ).animate({
      opacity: 1
    }, 500, function() {
      setTimeout( function() { document.location.href = $('.bbstro').attr('href'); }, 500 );
    });

  });

  $('a.privacy').unbind().on('click', function(e) {
    e.preventDefault();

    $.ajax({
      url : "/assets/pages/privacy.inc.php",
      dataType: "text",
      success : function (data) {
          $("#privacy-container").html(data);
      }
    });
  });

  $('[data-toggle="tooltip"]').tooltip();

  new WOW().init();

  $('#loginTabs a').click(function (e) {
    e.preventDefault();
    $(this).tab('show');
  })
});